function initGlostick(user, impersonatedUser = null) {
  let accountLinks = []

  const glostickOpts = {
    logoutUrl: '/logout',
    targetSelector: 'body',
    appName: 'Heroku Data',
    accountLinks: accountLinks,
    helmet: process.env.REACT_APP_GLOSTICK_HELMET || false,
    mainContentId: '#root',
    footerId: '#belostick'
  }

  glostickOpts.userEmail = impersonatedUser
    ? impersonatedUser.email
    : user.email
  glostickOpts.userName = impersonatedUser ? impersonatedUser.name : user.name

  if (user.email.endsWith('@heroku.com')) {
    const identityLink = impersonatedUser
      ? {
          href: '/release',
          label: 'Release Identity',
          icon: 'assumeIdentity'
        }
      : {
          href: '/impersonate',
          label: 'Assume Identity',
          icon: 'assumeIdentity'
        }

    accountLinks.push(identityLink)
  }

  // init glostick
  window.Glostick.init(glostickOpts)

  // init belostick
  window.Belostick.init({
    targetSelector: '#belostick',
    cta: {
      url: 'https://help.heroku.com',
      linkText: 'Support'
    }
  })
}

export default initGlostick
